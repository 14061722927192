<script setup lang="ts">
    import { useGetPostsQuery } from '~/composables/urql';

    const d = new Date();

	d.setUTCHours(0, 0, 0, 0);
    d.setMonth(d.getMonth() - 1);

    const { data, fetching } = await useGetPostsQuery({
        variables: {
            where: {
                created_at: { 
                    _gte: d
                }
            },
            offset: 0,
            limit: 6,
        }
    });
</script>

<template>
    <section class="w-full" v-if="data.uogorod_posts.length">
        <h3 class="text-2xl">Популярные статьи</h3>
        <div class="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
            <PostBlock class="" v-for="post in data.uogorod_posts" :key="post.id" :post="post" hide-actions="true" text-clamp="3" />
        </div>
    </section>
    
</template>